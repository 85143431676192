import React from "react";
import {
  Image,
  //   MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "./CallModal.module.scss";

const CallModal = ({ data, handleClose }) => {
  // const dispatch = useDispatch();
  // const handleClose = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.call_active = false;
  //       state.call_data = {};
  //     })
  //   );
  // };

  return (
    //     <div className={Style.root}>
    //       <div className={`pro-d-flex ${Style.head_wrap}`}>
    //         <Image
    //           src={
    //             data?.image_url
    //               ? data?.image_url
    //               : data?.gender === "Female"
    //               ? Assets.FemaleProfile
    //               : Assets.MaleProfile
    //           }
    //           propStyle={{ root: Style.img_wrap }}
    //           width={165}
    //           height={165}
    //         />
    //         <div className={Style.title_wrap}>
    //           <h5 className="pro-mb-1 pro-ttl">{data?.name ?? ""}</h5>
    //           <p
    //             className="pro-mb-0 pro-fw-medium pro-pnt"
    //             onClick={() =>
    //               window.open(
    //                 `${window.location.origin}/${`profile?active=${
    //                   data?.id
    //                 }&id=${""}`}`
    //               )
    //             }
    //           >
    //             {data?.web_id ?? "XXXXXXXXX"}
    //           </p>
    //         </div>
    //         <div className={Style.call_icon}>
    //           <span class={`material-symbols-outlined ${Style.icon}`}>
    //             ring_volume
    //           </span>
    //         </div>
    //       </div>
    //       <div className={`${Style.details_wrap}`}>
    //         <MultiColumnTable
    //           extraClassNames={Style.inner_table}
    //           data={[
    //             {
    //               label: "Religion",
    //               value: "Christian",
    //             },
    //             {
    //               label: "Height",
    //               value: "180cm",
    //             },
    //             {
    //               label: "Caste",
    //               value: "Roman catholic",
    //             },

    //             {
    //               label: "Id",
    //               value: "XXXXXXXXXX",
    //             },
    //           ]}
    //           title={"Call Details"}
    //         />
    //       </div>
    //     </div>
    <div className={Style.root}>
      <div className="pro-d-flex pro-justify-between pro-items-center pro-mb-3">
        <p className="pro-font-sm pro-fw-medium pro-mb-0">Incoming...</p>
        <span
          onClick={handleClose}
          className={`material-symbols-outlined x3 ${Style.icon}`}
        >
          close
        </span>
      </div>
      <Image
        src={
          data?.web_id
            ? data?.image_url
              ? data?.image_url
              : Assets.MaleProfile
            : Assets.UnknownCall
        }
        propStyle={{ root: Style.img_wrap }}
        width={165}
        height={165}
      />
      <div className={Style.title_wrap}>
        <div
          onClick={() => {
            if (data?.web_id) {
              window.open(
                `${window.location.origin}/${`profile?active=${
                  data?.id
                }&id=${""}`}`
              );
            }
          }}
          className={`flex-fill pro-text-center ${data?.web_id && Style.link}`}
        >
          <h4 className={`pro-ttl h4 ${Style.name}`}>{data?.name || ""}</h4>
          <p className={`pro-fw-medium pro-mb-0`}>
            {data?.web_id
              ? data.web_id
              : data?.mobile
              ? "*".repeat(Math.max(0, data.mobile.length - 3)) +
                data.mobile.slice(-3)
              : ""}
          </p>
        </div>
        {/* <div className={Style.call_icon}>
          <span className={`material-symbols-outlined ${Style.icon}`}>
            ring_volume
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default CallModal;
