import React from "react";
import { Input, IconText } from "@wac-ui-dashboard/wac_component_library";
import style from "../staffCreationForm.module.scss";

const RowThree = ({
  formik,
  params,
  showPassword,
  handleShowPassword,
  handleRemovePhotoImage,
  handleRemoveIdProofImage,
  handleRemoveCertificateImage,
}) => {
  return (
    <>
      <div className="row">
        <div className={`col-lg-4   col-xl-3 p-re`}>
          <Input
            label={"Password*"}
            type="password"
            id="password"
            name="password"
            autoComplete="new-password"
            className={`pro-input lg ${
              formik.touched.password && formik.errors.password && " error"
            } hide-icon-input`}
            {...formik.getFieldProps("password")}
            error={formik.touched.password && formik.errors.password}
            errorMessage={formik.errors.password}
          />
          {showPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>

        <div className={`col-lg-4   col-xl-3`}>
          <Input
            label={"Confirm Password*"}
            type="password"
            id="confirm_password"
            name="confirm_password"
            className={`pro-input lg ${
              formik.touched.confirm_password &&
              formik.errors.confirm_password &&
              " error"
            }`}
            {...formik.getFieldProps("confirm_password")}
            error={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
            errorMessage={formik.errors.confirm_password}
          />
        </div>

        <div className={`col-lg-4   col-xl-3`}>
          <Input
            label={"Agent Number"}
            type="text"
            id="agent_number"
            name="agent_number"
            className={`pro-input lg ${
              formik.touched.agent_number &&
              formik.errors.agent_number &&
              " error"
            }`}
            {...formik.getFieldProps("agent_number")}
            error={formik.touched.agent_number && formik.errors.agent_number}
            errorMessage={formik.errors.agent_number}
          />
        </div>
      </div>

      <hr className={`pro-mt-2 pro-mb-4`} />
      <div className="row">
        <div className={`col-lg-4   col-xl-3`}>
          <div className="input-wrap sm pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Photo*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept="image/*"
                className={`pro-input lg ${
                  formik.touched.photo && formik.errors.photo && " error"
                }`}
                id="photo"
                onBlur={formik.handleBlur("photo")}
                onChange={(e) =>
                  formik.setFieldValue("photo", e?.target?.files[0])
                }
              />
              {formik?.values?.photo ? (
                <span
                  className={`input-drag-box top ${style.checkbox_wrapper}`}
                >
                  <IconText
                    title={formik?.values?.photo?.name ?? formik?.values?.photo}
                  />
                  <span
                    className="material-symbols-outlined"
                    onClick={(e) => handleRemovePhotoImage(e)}
                  >
                    close
                  </span>
                </span>
              ) : (
                <span className="input-drag-box">
                  <IconText title={`Drop files to attach or browse`} />
                </span>
              )}
              {formik.touched.photo && formik.errors.photo && (
                <span className="error-text">{formik.errors.photo}</span>
              )}
            </div>
          </div>
        </div>

        <div className={`col-lg-4   col-xl-3`}>
          <div className="input-wrap sm pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Id Proof*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept="image/*"
                className={`pro-input lg ${
                  formik.touched.id_proof && formik.errors.id_proof && " error"
                }`}
                id="id_proof"
                onBlur={formik.handleBlur("id_proof")}
                onChange={(e) =>
                  formik.setFieldValue("id_proof", e?.target?.files[0])
                }
              />

              {formik?.values?.id_proof ? (
                <span
                  className={`input-drag-box top ${style.checkbox_wrapper}`}
                >
                  <IconText
                    title={
                      formik?.values?.id_proof?.name ?? formik?.values?.id_proof
                    }
                  />
                  <span
                    className="material-symbols-outlined"
                    onClick={(e) => handleRemoveIdProofImage(e)}
                  >
                    close
                  </span>
                </span>
              ) : (
                <span className="input-drag-box">
                  <IconText title={`Drop files to attach or browse`} />
                </span>
              )}

              {formik.touched.id_proof && formik.errors.id_proof && (
                <span className="error-text">{formik.errors.id_proof}</span>
              )}
            </div>
          </div>
        </div>

        <div className={`col-lg-4   col-xl-3`}>
          <div className="input-wrap sm pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Certificate*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept="image/*"
                className={`pro-input lg ${
                  formik.touched.certificate &&
                  formik.errors.certificate &&
                  " error"
                }`}
                id="certificate"
                onBlur={formik.handleBlur("certificate")}
                onChange={(e) =>
                  formik.setFieldValue("certificate", e?.target?.files[0])
                }
              />
              {formik?.values?.certificate ? (
                <span
                  className={`input-drag-box top ${style.checkbox_wrapper}`}
                >
                  <IconText
                    title={
                      formik?.values?.certificate?.name ??
                      formik?.values?.certificate
                    }
                  />
                  <span
                    className="material-symbols-outlined"
                    onClick={(e) => handleRemoveCertificateImage(e)}
                  >
                    close
                  </span>
                </span>
              ) : (
                <span className="input-drag-box">
                  <IconText title={`Drop files to attach or browse`} />
                </span>
              )}
              {formik.touched.certificate && formik.errors.certificate && (
                <span className="error-text">{formik.errors.certificate}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RowThree;
