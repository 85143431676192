import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api[method](`${endpoint}`, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const imageDeleteHistory = createApi({
  reducerPath: "imageDeleteHistoryApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Image-Delete-History"],
  endpoints: (builder) => ({
    getImageDeleteHistory: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `user/image-delete-history`,
      }),
      providesTags: ["Image-Delete-History"],
    }),
  }),
});

export const { useGetImageDeleteHistoryQuery } = imageDeleteHistory;
