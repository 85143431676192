import { useSelector, useDispatch } from "react-redux";
import { useGetImageDeleteHistoryQuery } from "../../store/queries/ImageDeleteHistory";
import { updateConfig } from "../../store/slices/ImageDeleteHistory/imageDeleteHistorySlice";
import { useState } from "react";
import { addDays } from "date-fns";
import { getFormatedDate } from "../../utils/functions/table";
import moment from "moment";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { UserIdRow } from "./Row";

const useImageDeleteHistory = () => {
  const dispatch = useDispatch();
  const activeProfile = sessionStorage.getItem("active");

  const imageDeleteHistoryState = useSelector(
    (state) => state.imageDeleteHistory
  );
  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });

  const time = {
    startTime: new Date(),
    endTime: new Date(),
  };

  const initialTimeRange = [
    new Date(time.startTime.setHours(0, 0, 0, 0)),
    new Date(time.endTime.setHours(23, 59, 59, 999)),
  ];

  const {
    data: imageDeleteHistoryData,
    isFetching,
    isLoading,
  } = useGetImageDeleteHistoryQuery({
    search: imageDeleteHistoryState.searchKey,
    per_page: imageDeleteHistoryState.currentPageSize,
    page: imageDeleteHistoryState.currentPage,
    date: imageDeleteHistoryState.dateRange,
    sort_by: imageDeleteHistoryState?.sortBy,
    sort: imageDeleteHistoryState?.sortOrder,
    time: imageDeleteHistoryState?.timeFilter,
  });
  const handleSort = (label) => {
    
    if (imageDeleteHistoryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            imageDeleteHistoryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      image: (field, data) => (
        <div className="no-wrap  pro-pnt">
          <IconText
            icon={<span className="material-symbols-outlined x4">task</span>}
            title={"View Image"}
            onClick={() => handleViewImage(data)}
          />
        </div>
      ),
      user_register_id: (_, data) => (
        <UserIdRow data={data} activeProfile={activeProfile} />
      ),
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleViewImage = (data) => {
    dispatch(
      updateConfig((state) => {
        state.showImageModal = true;
        state.imageData = data?.image;
      })
    );
  };

  const closeImageModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showImageModal = false;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.searchKey = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    setInitialDateRange(range);
    // formik?.setFieldValue("date", [
    //   getFormatedDate(range.startDate),
    //   getFormatedDate(range.endDate),
    // ]);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.dateRange = [
          getFormatedDate(range.startDate),
          getFormatedDate(range.endDate),
        ];
      })
    );
  };

  const handleGetValues = (values) => {
    if (values) {
      const formatTime = (date) => {
        const formattedTime = moment(date).format("HH:mm");
        if (formattedTime === "00:00") {
          return "00:00";
        } else {
          return formattedTime;
        }
      };
      let formattedTimes = [formatTime(values[0]), formatTime(values[1])];
      dispatch(
        updateConfig((state) => {
          state.timeFilter = formattedTimes;
          state.timeFilterValue = values;
        })
      );
    }
  };

  const handleClearValues = () => {
    dispatch(
      updateConfig((state) => {
        state.timeFilter = ["00:00", "23:59"];
        state.timeFilterValue = [
          new Date(time.startTime.setHours(0, 0, 0, 0)),
          new Date(time.endTime.setHours(23, 59, 59, 999)),
        ];
      })
    );
  };

  return {
    imageDeleteHistoryData,
    imageDeleteHistoryState,
    isFetching,
    paginationOptions,
    isLoading,
    initialDateRange,
    initialTimeRange,
    closeImageModal,
    handleGetValues,
    handleClearValues,
    handleDateChange,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
  };
};

export default useImageDeleteHistory;
