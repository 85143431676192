import React from "react";
import style from "./errorMessage.module.scss";
function ErrorMessage({ message, description }) {
  return (
    <div className={`${style.root}`}>
      <div className={style.icon}>
        <span className="material-symbols-outlined">error</span>
      </div>
      <div className={style.content_wrapper}>
        <h6 className={`${style.title} pro-ttl h6 pro-mb-1`}>
          {message || `Data not found!`}
        </h6>
        {description && <p className={`pro-mb-0`}>{description}</p>}
      </div>
    </div>
  );
}

export default ErrorMessage;
