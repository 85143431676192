import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import PartnerForm from "./PartnerForm";
import usePartnerMatch from "./usePartnerMatch";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import ErrorMessage from "../../Global/ErrorMessage";

const PartnerMatch = ({ star }) => {
  const {
    partnerMatchState,
    state,
    filters,
    activeFilter,
    showScroll,
    checked,
    selectedProfiles,
    activeProfile,
    entireData,
    adminVerified,
    registered,
    handleRegisteredCheckbox,
    handleAdminVerifiedCheckbox,
    handleEntireDataCheckBox,
    handleDownloadClick,
    handleSelect,
    handleCheckBox,
    lastElement,
    handleChange,
    handleScrollToTop,
  } = usePartnerMatch(star);
  return (
    <>
      <div className="pro-m-5 font-inc">
        {showScroll && (
          <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
            <span className="material-symbols-outlined">expand_less</span>
          </button>
        )}

        <div className="row pro-check-box">
          <div className="col-auto ">
            <SearchFilters
              data={filters}
              searchable={false}
              showActions={false}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleChange}
            />
          </div>
          <div className="col-auto pro-items-center pro-d-flex ">
            <input
              type="checkbox"
              className="pro-check "
              id="agreement"
              name="agreement"
              checked={checked ?? false}
              onChange={(e) => handleCheckBox(checked)}
            />
            <label htmlFor="agreement" className="pro-check-label">
              {" "}
              Agreement{" "}
            </label>
          </div>
          <div className="col-auto pro-items-center pro-d-flex ">
            <input
              type="checkbox"
              className="pro-check "
              id="entire_data"
              name="entire_data"
              checked={entireData ?? false}
              onChange={(e) => handleEntireDataCheckBox(entireData)}
            />
            <label htmlFor="entire_data" className="pro-check-label">
              {" "}
              Enitre Data{" "}
            </label>
          </div>
          <div className="col-auto pro-items-center pro-d-flex ">
            <input
              type="checkbox"
              className="pro-check "
              id="admin_verified"
              name="admin_verified"
              checked={adminVerified ?? false}
              onChange={(e) => handleAdminVerifiedCheckbox(adminVerified)}
            />
            <label htmlFor="admin_verified" className="pro-check-label">
              {" "}
              Admin Verified{" "}
            </label>
          </div>

          <div className="col-auto pro-items-center pro-d-flex ">
            <input
              type="checkbox"
              className="pro-check "
              id="registered"
              name="registered"
              checked={registered ?? false}
              onChange={(e) => handleRegisteredCheckbox(registered)}
            />
            <label htmlFor="registered" className="pro-check-label">
              {" "}
              Registered{" "}
            </label>
          </div>
        </div>
      </div>

      <PartnerForm
        star={star}
        checked={checked}
        activeFilter={activeFilter}
        entireData={entireData}
        adminVerified={adminVerified}
        registered={registered}
        state={state}
      />

      <div className={`pro-p-5`}>
        <UserDetailTableListing
          data={partnerMatchState?.data || []}
          lastElement={lastElement}
          handleSelect={handleSelect}
          checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
          showCheck
          handleDownload={handleDownloadClick}
        />
      </div>

      {state?.partnerMatchStatus === "idle" ||
      state?.partnerMatchStatus === "pending" ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(partnerMatchState?.data ? 2 : 6)].map((_, i) => (
              <div className={`col-6`} key={i}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : (
        partnerMatchState?.data?.length === 0 && <ErrorMessage />
      )}
    </>
  );
};

export default PartnerMatch;
