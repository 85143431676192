import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import ErrorMessage from "../../Global/ErrorMessage";
import UserDetailTableShimmer from "../../Global/Shimmers/UserDetailTableShimmer";
import UserDetailTableListing from "../../Global/UserDetailTableListing";
import useAutoStarMatch from "./useAutoStarMatch";

const AutoStarMatch = () => {
  const {
    autoStarMatchState,
    state,
    filters,
    activeFilter,
    showScroll,
    checked,
    selectedProfiles,
    activeProfile,
    entireData,
    adminVerified,
    registered,
    handleRegisteredCheckbox,
    handleAdminVerifiedCheckbox,
    handleEntireDataCheckBox,
    handleDownloadClick,
    handleSelect,
    handleCheckBox,
    lastElement,
    handleChange,
    handleScrollToTop,
  } = useAutoStarMatch();
  return (
    <div className="pro-m-5 font-inc">
      {showScroll && (
        <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
          <span className="material-symbols-outlined">expand_less</span>
        </button>
      )}

      <div className="row pro-check-box">
        <div className="col-auto ">
          <SearchFilters
            data={filters}
            searchable={false}
            showActions={false}
            activeFilter={activeFilter}
            handleButtonGroupChange={handleChange}
          />
        </div>
        <div className="col-auto pro-items-center pro-d-flex ">
          <input
            type="checkbox"
            className="pro-check "
            id="agreement"
            name="agreement"
            checked={checked ?? false}
            onChange={(e) => handleCheckBox(checked)}
          />
          <label htmlFor="agreement" className="pro-check-label">
            {" "}
            Agreement{" "}
          </label>
        </div>
        <div className="col-auto pro-items-center pro-d-flex ">
          <input
            type="checkbox"
            className="pro-check "
            id="entire_data"
            name="entire_data"
            checked={entireData ?? false}
            onChange={(e) => handleEntireDataCheckBox(entireData)}
          />
          <label htmlFor="entire_data" className="pro-check-label">
            {" "}
            Enitre Data{" "}
          </label>
        </div>
        <div className="col-auto pro-items-center pro-d-flex ">
          <input
            type="checkbox"
            className="pro-check "
            id="admin_verified"
            name="admin_verified"
            checked={adminVerified ?? false}
            onChange={(e) => handleAdminVerifiedCheckbox(adminVerified)}
          />
          <label htmlFor="admin_verified" className="pro-check-label">
            {" "}
            Admin Verified{" "}
          </label>
        </div>
        <div className="col-auto pro-items-center pro-d-flex ">
          <input
            type="checkbox"
            className="pro-check "
            id="registered"
            name="registered"
            checked={registered ?? false}
            onChange={(e) => handleRegisteredCheckbox(registered)}
          />
          <label htmlFor="registered" className="pro-check-label">
            {" "}
            Registered{" "}
          </label>
        </div>
      </div>
      <UserDetailTableListing
        data={autoStarMatchState?.data || []}
        lastElement={lastElement}
        handleSelect={handleSelect}
        checked={selectedProfiles?.filter?.((a) => a !== activeProfile)}
        showCheck
        handleDownload={handleDownloadClick}
      />
      {state.autoStarMatchStatus === "idle" ||
      state.autoStarMatchStatus === "pending" ? (
        <div className={`col-12 pro-mt-4`}>
          <div className={`row g-4`}>
            {[...Array(autoStarMatchState?.data ? 2 : 6)].map((_, i) => (
              <div className={`col-6`}>
                <UserDetailTableShimmer />
              </div>
            ))}
          </div>
        </div>
      ) : (
        autoStarMatchState?.data?.length === 0 && <ErrorMessage />
      )}
    </div>
  );
};

export default AutoStarMatch;
