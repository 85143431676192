import React from "react";
import {
  HeadingGroup,
  SearchFilters,
  Table,
  Pagination,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import { DateRangePicker } from "rsuite";
import useImageDeleteHistory from "./useImageDeleteHistory";
import { ImagePdfCard } from "../Global/ImagePdfCard";

const ImageDeleteHistory = () => {
  const {
    imageDeleteHistoryData,
    imageDeleteHistoryState,
    isFetching,
    paginationOptions,
    isLoading,
    initialDateRange,
    initialTimeRange,
    closeImageModal,
    handleClearValues,
    handleGetValues,
    handleDateChange,
    handleSearch,
    handleClearClick,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
  } = useImageDeleteHistory();
  return (
    <>
      <div className="pro-mt-2">
        <HeadingGroup
          title={"Image Delete History"}
          extraClassName={`pro-mb-2`}
        />
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                imageDeleteHistoryState?.searchKey === "" ? false : true
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: imageDeleteHistoryState?.searchKey }}
              showActions={false}
              //date picker props
              showDateRange={true}
              initialDateRange={initialDateRange}
              onDateChange={handleDateChange}
            />
          </div>
        </div>
        <div className={`col-auto pro-pb-6`}>
          <Table
            data={imageDeleteHistoryData?.data?.data || []}
            uniqueID={"id"}
            fields={imageDeleteHistoryData?.data?.field}
            showCheckBox={false}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={imageDeleteHistoryState?.currentPageSize}
            // handleEdit={handleTableEditClick}
            assignable={false}
            deletable={false}
            multiSelect={false}
          />
          {imageDeleteHistoryData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={imageDeleteHistoryState.currentPage}
              totalPageCount={Math.ceil(
                imageDeleteHistoryData?.data?.total_count /
                  imageDeleteHistoryState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
        <ModalLayout
          show={imageDeleteHistoryState?.showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImagePdfCard
            data={imageDeleteHistoryState?.imageData}
            documentType={"image"}
            handleClose={closeImageModal}
          />
        </ModalLayout>
      </div>
    </>
  );
};

export default ImageDeleteHistory;
