import Assets from "../../../assets/Assets";
import Style from "./ImagePdfCard.module.scss";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export function ImagePdfCard({ data, documentType, handleClose }) {
  return (
    <div className={`${Style.root} pro-p-2`}>
      <TransformWrapper>
        {({ zoomIn, zoomOut }) => (
          <>
            <div className={Style.action_icon}>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={handleClose}
              >
                <span className="material-symbols-outlined">close</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomIn()}
              >
                <span className="material-symbols-outlined">zoom_in</span>
              </button>
              <button
                className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                onClick={() => zoomOut()}
              >
                <span className="material-symbols-outlined">zoom_out</span>
              </button>
            </div>
            <TransformComponent>
              <div className={`${Style.root_image} pro-mb-1`}>
                {data ? (
                  documentType === "pdf" ? (
                    <div className={Style.root_pdf}>
                      <iframe
                        title="PDF Viewer"
                        src={data || Assets.NoDocument}
                        height="700px"
                        width="100%"
                      />
                    </div>
                  ) : (
                    <div className={Style.root_figure}>
                      <figure className={`${Style.image}`}>
                        <img
                          src={data ?? Assets.NoDocument}
                          alt={"document"}
                          height={`100%`}
                          width={`100%`}
                        />
                      </figure>
                    </div>
                  )
                ) : (
                  <div className={Style.root_figure}>
                    <div className={`shine ${Style.thumb}`} />
                  </div>
                )}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
}
